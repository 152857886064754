import React from "react";
import Info from "./Info";
import Main from "./Main";
import Faq from "./Faq";

function SelectPhone() {
  return (
    <>
      <Info />
      <Main />
      <Faq />
    </>
  );
}

export default SelectPhone;
