import styled from "styled-components";

import Logo from "../../assets/img/logo.png";

export const Container = styled.div``;

export const Menu = styled.div`
  width: 100%;
  height: 110px;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 5%;
  padding-right: 5%;
  background: var(--primary-dark);
  border-bottom: 2px solid var(--primary-main);

  & + a {
    max-width: 100px;
  }
`;

export const LogoImage = styled.img.attrs({
  src: Logo,
  alt: "Compro iPhone",
})`
  height: 90px;
`;

export const CTAButton = styled.button`
  display: inline-block;

  box-sizing: border-box;
  outline: none;
  padding: 16px 24px;
  border: 1px solid var(--primary-darker);
  border-radius: 5px;
  cursor: pointer;

  color: var(--font-color);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    opacity: 0.5;
  }
  
`;
