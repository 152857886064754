import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import SelectPhone from "./pages/SelectPhone";
import PhoneAttributes from "./pages/PhoneAttributes";
import Checkout from "./pages/Checkout";

export default function Routes() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route path="/" exact component={SelectPhone} />
        <Route path="/attributes" exact component={PhoneAttributes} />
        <Route path="/checkout" exact component={Checkout} />
      </Switch>

      <Footer />
    </Router>
  );
}
