import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  margin-top: 150px;
`;

export const CheckboxStyleContainer = styled.label`
  /* display: flex;
  align-items: center;
  justify-content: center; */

  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 10px 10px 0px 10px;
  padding: 10px 20px;

  color: var(--primary);
  font-size: 16px;
  font-weight: bold;

  cursor: pointer;

  border: 0px;
  border-radius: 4px;
  background: ${(props) =>
    props.selected ? "green" : "var(--primary-button)"};

  transition: background 0.3s;

  &:hover {
    background: #fa4f00a1;
  }

  & strong {
    margin-left: 20px;
  }
`;

export const AcessoriesCheckbox = styled.input.attrs({
  type: "radio",
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  & ~ span {
    background-color: #ccc;
  }

  &:checked ~ span {
    background-color: var(--primary-button);
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 8fr;
`;

export const ArrowsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  margin-top: 40px;
  background: ${(props) => (props.return ? "#b9b532" : "#50b0e1")};

  width: 200px;
  padding: 20px;

  border: 0;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${(props) => (props.return ? "#d2cc16" : "#2c8cbd")};
  }
`;

export const FinishButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;

  margin-top: 40px;
  background: #29882c;

  width: 200px;
  padding: 20px;

  border: 0;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #2bb32f;
  }

  margin-left: 10px;
`;
