import React from "react";

//Assets
import iphoneIcon from "../../../assets/img/iphonex.svg";
import Step1 from "../../../assets/img/step-1.svg";
import Step2 from "../../../assets/img/step-2.svg";
import Step3 from "../../../assets/img/step-3.svg";
import Step4 from "../../../assets/img/step-4.svg";
import Why1 from "../../../assets/img/why-1.svg";
import Why2 from "../../../assets/img/why-2.svg";
import Why3 from "../../../assets/img/why-3.svg";


import "./index.css";

function Faq() {
  return (
    <div className="faq-container">
      <div className="como-vender">
        <span>É muito fácil vender seu iPhone usado na Compro iPhone:</span>
        <p>
          Saiba como vender seu iPhone sem burocracia e receber o seu pagamento,
          sem sair de casa!
        </p>
        <div className="passos">
          <div className="passo">
            <img src={Step1} alt="Passo 1" />
            <span>Passo 1</span>
            <p>
             Selecione o modelo e condição para conhecer a oferta pelo seu aparelho usado
            </p>
          </div>

          <div className="passo">
            <img src={Step2} alt="Passo 2" />
            <span>Passo 2</span>
            <p>
              Ao aceitar a oferta, complete seus dados e selecione a forma como deseja receber seu pagamento.
            </p>
          </div>

          <div className="passo">
            <img src={Step3} alt="Passo 3" />
            <span>Passo 3</span>
            <p>
              Elimine todos os dados, arquivos e senhas do seu aparelho e envie para a Trocafone.
            </p>
          </div>

          <div className="passo">
            <img src={Step4} alt="Passo 4" />
            <span>Passo 4</span>
            <p>
              Assim que comprovarmos a condição do aparelho, você receberá seu dinheiro, de acordo com a forma de pagamento selecionada.
            </p>
          </div>
        </div>
      </div>

      <div className="venda-iphone">
        <span>Por que vender para a Compro iPhone?</span>
       <div className="stepsSection">
       <div className="step">
          <div className="single-step">
            <img src={Why1} alt="Passo 4" />
            <p>
              Você vende de maneira segura e o envio do seu aparelho é grátis.
            </p>
          </div>
          <div className="single-step">
            <img src={Why2} alt="Passo 4" />
            <p>
              Receba seu pagamento na sua conta bancária ou conta do Mercado Pago.
            </p>
          </div>
          <div className="single-step">
            <img src={Why3} alt="Passo 4" />
            <p>
              Você dá novo uso ao seu aparelho e evitar acúmulo de lixo eletrônico.o
            </p>
          </div>
        </div>
       </div>
      </div>
    </div>
  );
}

export default Faq;
