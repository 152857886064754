import React from "react";

import "./index.css";

function Info() {
  return (
    <div className="Container">
      <h1 className="Title-Content">
        Entenda como funciona a venda de iPhone
      </h1>
      <p className="Paragraph-Content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        fermentum sodales odio et posuere. Sed diam odio, accumsan at libero in,
        efficitur lobortis ipsum. Sed vitae ullamcorper ante. Nunc consectetur
        leo ligula. Phasellus bibendum volutpat justo vel lacinia. Aliquam
        mauris neque, cursus eu pharetra a, porttitor quis erat. Aliquam blandit
        viverra suscipit. Interdum et malesuada fames ac ante ipsum primis in
        faucibus.
      </p>
    </div>
  );
}

export default Info;
