import styled from "styled-components";

export const Container = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 120px;
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: #9e9e9e7a;

  margin: 20px 0;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 26px;
`;

export const Discount = styled.div``;

export const PaymentCard = styled.div`
  background: var(--primary-main);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 650px;
  margin-top: 40px;
  padding: 20px;
`;

export const PaymentCardHeader = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--footer-background);

  width: 100%;
  padding: 15px 0;
  color: var(--primary);
`;

export const PaymentContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
`;

export const PhoneName = styled.h3`
  text-transform: uppercase;
  color: var(--dark);
`;

export const BuyPrice = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  & span,
  & strong {
    color: var(--dark);
  }
`;

export const AcessoriesContainer = styled.div`
  width: 400px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const AcessoriesTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--dark);
`;

export const AcessoriesItems = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    color: var(--dark);
  }

  & strong {
    color: #2ecc71;
  }
`;

export const DefectsContainer = styled.div`
  width: 400px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const DefectsTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--dark);
`;

export const DefectsItems = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    color: var(--dark);
  }

  & strong {
    color: #e74c3c;
  }
`;

export const SubtotalContainer = styled.div`
  width: 400px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WarningContainer = styled.div`
  width: 400px;
  
  margin-top: 30px;
  background: #e74c3c;
  padding: 10px;
`;

export const WarningText = styled.span`
  color: #FFF;
`;

export const FinalPrice = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background: #2ecc71;
  padding: 20px 0;

  color: #fff;
`;

export const ActionContainer = styled.footer`
  margin-top: 20px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SellButton = styled.button.attrs({
  type: "button",
})`
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: center;

  margin: 10px 10px 0px 10px;
  padding: 15px;

  color: var(--primary);
  font-size: 16px;
  font-weight: bold;

  cursor: pointer;

  border: 0px;
  border-radius: 4px;
  background: ${(props) =>
    props.selected ? "green" : "var(--primary-button)"};

  transition: background 0.3s;

  &:hover {
    background: #fa4f00a1;
  }
`;
