const IphonesModelos = [
  {
    id: 1,
    title: "iPhone 5",
    memory: [16, 32, 64],
    color: ["Prateado", "Cinza Espacial", "Dourado"],
    acessories: {
      "Cabo USB Original": 5,
      "Fone de Ouvido Original": 5,
      "Caixa Original": 5,
      "Carregador Original": 5,
    },
    defects: {
      Tela: -90,
      "Botão Home": -25,
      "Botão Volume": -25,
      "Câmera Frontal": -40,
      "Câmera Traseira": -35,
      "Parte Traseira": -95,
      "Botão Vibrar": -25,
      "Botão Power": -25,
      "Entrada Cabo de Dados": -25,
      "Entrada Fone de Ouvido": -25,
      "Sensor de Proximidade": -25,
      Microfone: -25,
      Auricular: -25,
      Dock: -25,
      "Pixel Queimado": -35,
      "Pequenas Marcas Traseiras": -25,
    },
    price: 100,
  },
  {
    id: 2,
    title: "iPhone 5c",
    memory: [8, 16, 32, 64],
    color: ["Amarelo", "Verde", "Branco", "Azul", "Rosa"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -70,
      },
      {
        name: "Botão Home",
        price: -25,
      },
      {
        name: "Botão Volume",
        price: -25,
      },
      {
        name: "Câmera Frontal",
        price: -40,
      },
      {
        name: "Câmera Traseira",
        price: -35,
      },
      {
        name: "Parte Traseira",
        price: -75,
      },
      {
        name: "Botão Vibrar",
        price: -25,
      },
      {
        name: "Botão Power",
        price: -25,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -25,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -25,
      },
      {
        name: "Sensor de Proximidade",
        price: -25,
      },
      {
        name: "Microfone",
        price: -25,
      },
      {
        name: "Auricular",
        price: -25,
      },
      {
        name: "Bandeija chip",
        price: -15,
      },
      {
        name: "Pixel Queimado",
        price: -35,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -45,
      },
    ],
    minPrice: 25,
    initialPrice: 75,
  },
  {
    id: 3,
    title: "iPhone 5s",
    memory: [16, 32, 64],
    color: ["Prateado", "Cinza Espacial", "Dourado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -100,
      },
      {
        name: "Botão Volume",
        price: -35,
      },
      {
        name: "Câmera Frontal",
        price: -45,
      },
      {
        name: "Câmera Traseira",
        price: -35,
      },
      {
        name: "Parte Traseira",
        price: -110,
      },
      {
        name: "Bateria",
        price: -50,
      },
      {
        name: "Botão Vibrar",
        price: -35,
      },
      {
        name: "Botão Power",
        price: -35,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -35,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -35,
      },
      {
        name: "Sensor de Proximidade",
        price: -35,
      },
      {
        name: "Microfone",
        price: -35,
      },
      {
        name: "Auricular",
        price: -35,
      },
      {
        name: "Botão Home",
        price: -100,
      },
      {
        name: "Bandeija chip",
        price: -25,
      },
      {
        name: "Dock",
        price: -35,
      },
      {
        name: "Pixel Queimado",
        price: -35,
      },
      {
        name: "Mancha Câmera",
        price: -25,
      },
      {
        name: "WiFi",
        price: -25,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -55,
      },
    ],
    minPrice: 25,
    initialPrice: 170,
  },
  {
    id: 4,
    title: "iPhone 6",
    memory: [16, 32, 64, 128],
    color: ["Prateado", "Cinza Espacial", "Dourado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -135,
      },
      {
        name: "Botão Volume",
        price: -50,
      },
      {
        name: "Câmera Frontal",
        price: -50,
      },
      {
        name: "Câmera Traseira",
        price: -45,
      },
      {
        name: "Parte Traseira",
        price: -130,
      },
      {
        name: "Bateria",
        price: -75,
      },
      {
        name: "Botão Vibrar",
        price: -50,
      },
      {
        name: "Botão Power",
        price: -40,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -40,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -40,
      },
      {
        name: "Sensor de Proximidade",
        price: -40,
      },
      {
        name: "Microfone",
        price: -40,
      },
      {
        name: "Auricular",
        price: -40,
      },
      {
        name: "Bandeija chip",
        price: -20,
      },
      {
        name: "Botão Home",
        price: -120,
      },
      {
        name: "Dock",
        price: -40,
      },
      {
        name: "WiFi",
        price: -60,
      },
      {
        name: "Pixel Queimado",
        price: -50,
      },
      {
        name: "Mancha Câmera",
        price: -40,
      },
      {
        name: "Alto Falante",
        price: -40,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -55,
      },
    ],
    minPrice: 25,
    initialPrice: 480,
  },
  {
    //iPhone 6 Plus
    id: 5,
    title: "iPhone 6 plus",
    memory: [16, 64, 128],
    color: ["Prateado", "Cinza Espacial", "Dourado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -155,
      },
      {
        name: "Botão Volume",
        price: -65,
      },
      {
        name: "Câmera Frontal",
        price: -60,
      },
      {
        name: "Câmera Traseira",
        price: -110,
      },
      {
        name: "Parte Traseira",
        price: -180,
      },
      {
        name: "Bateria",
        price: -80,
      },
      {
        name: "Botão Vibrar",
        price: -65,
      },
      {
        name: "Botão Power",
        price: -55,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -55,
      },
      {
        name: "Sensor de Proximidade",
        price: -55,
      },
      {
        name: "Dock",
        price: -55,
      },
      {
        name: "Microfone",
        price: -55,
      },
      {
        name: "Auricular",
        price: -55,
      },
      {
        name: "Bandeja Chip",
        price: -25,
      },
      {
        name: "Botão Home",
        price: -155,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -55,
      },
      {
        name: "Pixel Queimado",
        price: -60,
      },
      {
        name: "Mancha Câmera",
        price: -45,
      },
      {
        name: "Alto Falante",
        price: -50,
      },
      {
        name: "WiFi",
        price: -60,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -65,
      },
    ],
    minPrice: 25,
    initialPrice: 580,
  },
  {
    //iPhone 6S
    id: 6,
    title: "iPhone 6s",
    memory: [16, 32, 64, 128],
    color: ["Prateado", "Cinza Espacial", "Dourado", "Ouro Rosa"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -165,
      },
      {
        name: "Botão Volume",
        price: -55,
      },
      {
        name: "Câmera Frontal",
        price: -50,
      },
      {
        name: "Câmera Traseira",
        price: -90,
      },
      {
        name: "Parte Traseira",
        price: -140,
      },
      {
        name: "Bateria",
        price: -95,
      },
      {
        name: "Botão Vibrar",
        price: -85,
      },
      {
        name: "Botão Power",
        price: -45,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -45,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -45,
      },
      {
        name: "Sensor de Proximidade",
        price: -55,
      },
      {
        name: "Microfone",
        price: -45,
      },
      {
        name: "Auricular",
        price: -45,
      },
      {
        name: "Botão Home",
        price: -180,
      },
      {
        name: "Dock",
        price: -45,
      },
      {
        name: "Pixel Queimado",
        price: -55,
      },
      {
        name: "Mancha Câmera",
        price: -50,
      },
      {
        name: "Alto Falante",
        price: -45,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -70,
      },
    ],
    minPrice: 25,
    initialPrice: 560,
  },
  {
    //iphone 6S Plus
    id: 7,
    title: "iPhone 6s Plus",
    memory: [16, 32, 64, 128],
    color: ["Prateado", "Cinza Espacial", "Dourado", "Ouro Rosa"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -175,
      },
      {
        name: "Botão Volume",
        price: -55,
      },
      {
        name: "Câmera Frontal",
        price: -120,
      },
      {
        name: "Câmera Traseira",
        price: -210,
      },
      {
        name: "Parte Traseira",
        price: -140,
      },
      {
        name: "Bateria",
        price: -85,
      },
      {
        name: "Botão Vibrar",
        price: -70,
      },
      {
        name: "Botão Power",
        price: -45,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -45,
      },
      {
        name: "Dock",
        price: -45,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -45,
      },
      {
        name: "Sensor de Proximidade",
        price: -55,
      },
      {
        name: "Auricular",
        price: -45,
      },
      {
        name: "Bandeja Chip",
        price: -25,
      },
      {
        name: "Microfone",
        price: -45,
      },
      {
        name: "Botão Home",
        price: -1800,
      },
      {
        name: "Pixel Queimado",
        price: -70,
      },
      {
        name: "Mancha Câmera",
        price: -65,
      },
      {
        name: "Alto Falante",
        price: -45,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -70,
      },
    ],
    minPrice: 25,
    initialPrice: 630,
  },
  {
    //iPhone 7
    id: 8,
    title: "iPhone 7",
    memory: [32, 128, 256],
    color: [
      "RED",
      "Black Piano",
      "Preto Matte",
      "Ouro Rosa",
      "Dourado",
      "Prateado",
    ],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
      {
        name: "Adaptador Fone de Ouvido",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -170,
      },
      {
        name: "Bateria",
        price: -80,
      },
      {
        name: "Parte Traseira",
        price: -200,
      },
      {
        name: "Câmera Traseira",
        price: -120,
      },
      {
        name: "Câmera",
        price: -140,
      },
      {
        name: "Botão Vibrar",
        price: -70,
      },
      {
        name: "Botão Power",
        price: -60,
      },
      {
        name: "Botão Volume",
        price: -95,
      },
      {
        name: "Auricular",
        price: -70,
      },
      {
        name: "Câmera Frontal",
        price: -120,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -60,
      },
      {
        name: "Dock",
        price: -60,
      },
      {
        name: "Bandeja Chip",
        price: -30,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -55,
      },
      {
        name: "Sensor de Proximidade",
        price: -75,
      },
      {
        name: "Microfone",
        price: -45,
      },
      {
        name: "Botão Home",
        price: -100,
      },
      {
        name: "Pixel Queimado",
        price: -70,
      },
      {
        name: "Mancha Câmera",
        price: -70,
      },
      {
        name: "Alto Falante",
        price: -60,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -85,
      },
    ],
    minPrice: 25,
    initialPrice: 840,
  },
  {
    //iPhone 7 Plus
    id: 9,
    title: "iPhone 7 Plus",
    memory: [32, 128, 256],
    color: ["Black Piano", "Preto Matte", "Ouro Rosa", "Dourado", "Prateado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
      {
        name: "Adaptador Fone de Ouvido",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -20,
      },
      {
        name: "Bateria",
        price: -90,
      },
      {
        name: "Parte Traseira",
        price: -280,
      },
      {
        name: "Câmera Traseira",
        price: -410,
      },
      {
        name: "Botão Vibrar",
        price: -75,
      },
      {
        name: "Botão Power",
        price: -55,
      },
      {
        name: "Botão Volume",
        price: -80,
      },
      {
        name: "Bandeja Chip",
        price: -35,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -60,
      },
      {
        name: "Dock",
        price: -55,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -60,
      },
      {
        name: "Sensor de Proximidade",
        price: -70,
      },
      {
        name: "Microfone",
        price: -60,
      },
      {
        name: "Auricular",
        price: -60,
      },
      {
        name: "Câmera Frontal",
        price: -220,
      },
      {
        name: "Botão Home",
        price: -1100,
      },
      {
        name: "Pixel Queimado",
        price: -70,
      },
      {
        name: "Mancha Câmera",
        price: -90,
      },
      {
        name: "Alto Falante",
        price: -60,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -140,
      },
    ],
    minPrice: 25,
    initialPrice: 1050,
  },
  {
    //iPhone 8
    id: 10,
    title: "iPhone 8",
    memory: [64, 256],
    color: ["RED", "Cinza Espacial", "Dourado", "Prateado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
      {
        name: "Adaptador Fone de Ouvido",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -185,
      },
      {
        name: "Botão Volume",
        price: -120,
      },
      {
        name: "Câmera Traseira",
        price: -360,
      },
      {
        name: "Parte Traseira",
        price: -350,
      },
      {
        name: "Bateria",
        price: -100,
      },
      {
        name: "Botão Vibrar",
        price: -110,
      },
      {
        name: "Botão Power",
        price: -110,
      },
      {
        name: "Dock",
        price: -110,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -110,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -110,
      },
      {
        name: "Bandeja Chip",
        price: -60,
      },
      {
        name: "Câmera Frontal",
        price: -180,
      },
      {
        name: "Sensor de Proximidade",
        price: -110,
      },
      {
        name: "Microfone",
        price: -110,
      },
      {
        name: "Auricular",
        price: -110,
      },
      {
        name: "Pixel Queimado",
        price: -80,
      },
      {
        name: "Mancha Câmera",
        price: -90,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -110,
      },
    ],
    minPrice: 25,
    initialPrice: 1160,
  },
  {
    // iPhone 8 Plus
    id: 11,
    title: "iPhone 8 Plus",
    memory: [64, 256],
    color: ["RED", "Cinza Espacial", "Dourado", "Prateado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
      {
        name: "Adaptador Fone de Ouvido",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -185,
      },
      {
        name: "Botão Volume",
        price: -120,
      },
      {
        name: "Câmera Traseira",
        price: -360,
      },
      {
        name: "Parte Traseira",
        price: -350,
      },
      {
        name: "Bateria",
        price: -100,
      },
      {
        name: "Botão Vibrar",
        price: -110,
      },
      {
        name: "Botão Power",
        price: -110,
      },
      {
        name: "Dock",
        price: -110,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -110,
      },
      {
        name: "Entrada Fone de Ouvido",
        price: -110,
      },
      {
        name: "Bandeja Chip",
        price: -60,
      },
      {
        name: "Câmera Frontal",
        price: -180,
      },
      {
        name: "Sensor de Proximidade",
        price: -110,
      },
      {
        name: "Microfone",
        price: -110,
      },
      {
        name: "Auricular",
        price: -110,
      },
      {
        name: "Pixel Queimado",
        price: -80,
      },
      {
        name: "Mancha Câmera",
        price: -90,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -110,
      },
    ],
    minPrice: 25,
    initialPrice: 1160,
  },
  {
    //iPhone SE
    id: 12,
    title: "iPhone SE",
    memory: [16, 32, 64, 128],
    color: ["Preto", "Branco", "RED"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Fone de Ouvido Original",
        price: 5,
      },
      {
        name: "Caixa Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Tela",
        price: -100,
      },
      {
        name: "Botão Volume",
        price: -30,
      },
      {
        name: "Câmera Frontal",
        price: -65,
      },
      {
        name: "Câmera Traseira",
        price: -35,
      },
      {
        name: "Parte Traseira",
        price: -130,
      },
      {
        name: "Bateria",
        price: -70,
      },
      {
        name: "Botão Vibrar",
        price: -25,
      },
      {
        name: "Botão Power",
        price: -25,
      },
      {
        name: "Dock",
        price: -30,
      },
      {
        name: "Entrada Cabo de Dados",
        price: -25,
      },
      {
        name: "Sensor de Proximidade",
        price: -20,
      },
      {
        name: "Microfone",
        price: -25,
      },
      {
        name: "Auricular",
        price: -25,
      },
      {
        name: "Bandeja Chip",
        price: -20,
      },
      {
        name: "Botão Home",
        price: -130,
      },
      {
        name: "Pixel Queimado",
        price: -50,
      },
      {
        name: "Mancha Câmera",
        price: -40,
      },
    ],
    minPrice: 25,
    initialPrice: 330,
  },
  {
    //iPhone SE 2
    id: 13,
    title: "iPhone SE 2",
    memory: [64, 128, 256],
    color: ["Preto", "Branco", "RED"],
    initialPrice: 1440,
  },
  {
    //iPhone X
    id: 14,
    title: "iPhone X",
    memory: [64, 256],
    color: ["Cinza Espacial", "Prateado"],
    acessories: [
      {
        name: "Cabo USB Original",
        price: 5,
      },
      {
        name: "Carregador Original",
        price: 5,
      },
    ],
    defects: [
      {
        name: "Pixel Queimado",
        price: -90,
      },
      {
        name: "Mancha Câmera",
        price: -200,
      },
      {
        name: "Tela",
        price: -1000,
      },
      {
        name: "Parte Traseira",
        price: -500,
      },
      {
        name: "Bateria",
        price: -200,
      },
      {
        name: "Entrada cabo de dados",
        price: -300,
      },
      {
        name: "Câmera Frontal",
        price: -250,
      },
      {
        name: "Câmera Traseira",
        price: -400,
      },
      {
        name: "Pequenas Marcas Traseiras",
        price: -200,
      },
    ],
    minPrice: 25,
    initialPrice: 1780,
  },
  {
    //iPhone XR
    id: 15,
    title: "iPhone XR",
    memory: [64, 128, 256],
    color: ["Preto", "Branco", "Amarelo", "Vermelho", "Coral", "Azul"],
    defects: [
      {
        name: "Pequenas Marcas Traseiras",
        price: -260,
      },
      {
        name: "Bateria",
        price: -500,
      },
      {
        name: "Parte Traseira",
        price: -550,
      },
      {
        name: "Câmera Frontal",
        price: -300,
      },
      {
        name: "Câmera Traseira",
        price: -600,
      },
      {
        name: "Tela",
        price: -1000,
      },
    ],
    minPrice: 25,
    initialPrice: 1550,
  },
  {
    //iPhone XS
    id: 16,
    title: "iPhone XS",
    memory: [64, 256, 512],
    color: ["Dourado", "Cinza Espacial", "Prateado"],
    defects: [
      {
        name: "Mancha Câmera",
        price: -250,
      },
      {
        name: "Bateria",
        price: -500,
      },
      {
        name: "Parte Traseira",
        price: -550,
      },
      {
        name: "Câmera Frontal",
        price: -260,
      },
      {
        name: "Câmera Traseira",
        price: -700,
      },
      {
        name: "Pequenas Marcas Traseira",
        price: -260,
      },
      {
        name: "Tela",
        price: -1250,
      },
    ],
    minPrice: 25,
    initialPrice: 2100,
  },
  {
    //iPhone XS Max
    id: 17,
    title: "iPhone XS Max",
    memory: [64, 256, 512],
    color: ["Dourado", "Cinza Espacial", "Prateado"],
    defects: [
      {
        name: "Mancha Câmera",
        price: -250,
      },
      {
        name: "Bateria",
        price: -600,
      },
      {
        name: "Parte Traseira",
        price: -600,
      },
      {
        name: "Camera Frontal",
        price: -450,
      },
      {
        name: "Camera Traseira",
        price: -800,
      },
      {
        name: "Pequenas ,arcas Traseiras",
        price: -280,
      },
      {
        name: "Tela",
        price: -1500,
      },
    ],
    minPrice: 25,
    initialPrice: 2220,
  },
  {
    //iPhone 11
    id: 18,
    title: "iPhone 11",
    memory: [64, 128, 256],
    color: ["Branco", "Preto", "Verde", "Amarelo", "Roxo", "RED"],
    defects: [
      {
        name: "Pixel Queimado",
        price: -120,
      },
    ],
    minPrice: 25,
    initialPrice: 2270,
  },
  {
    id: 19,
    title: "iPhone 11 Pro",
    memory: [64, 256, 512],
    color: ["Cinza Espacial", "Prateado", "Verde Meia-Noite", "Dourado"],
    initialPrice: 3350,
  },
  {
    id: 20,
    title: "iPhone 11 Pro Max",
    memory: [64, 256, 512],
    color: ["Cinza Espacial", "Prateado", "Verde Meia-Noite", "Dourado"],
    initialPrice: 3650,
  },
];

export default IphonesModelos;
