import React from "react";

import { Coffee, Heart } from "@styled-icons/feather";

import {
  Container,
  Location,
  LocationContent,
  Copyright,
  CopyrightContent,
} from "./styles";

function Footer() {
  return (
    <Container>
      <Location>
        <LocationContent>
          Trocafone – Comercialização de Aparelhos Eletrônicos LTDA / CNPJ:
          20.553.221/0001-02 / Endereço: Rua Frei Galvão, 91, Jardim Paulistano,
          São Paulo - SP. CEP 01454-060 (não atendemos nesse endereço.
          Comercializamos celulares, exclusivamente, por meio do nosso site e
          nos quiosques). Atendimento ao cliente: Clique aqui l Para informações
          relacionadas à imprensa, entre em contato com: 1dois@1dois.com.br
        </LocationContent>
      </Location>
      <Copyright>
        <CopyrightContent>
          Copyright © {new Date().getFullYear()} - Todos direitos reservados |
          Desenvolvido com <Heart size={18} color="#333" /> e{" "}
          <Coffee size={18} color="#333" /> por{" "}
          <a href="https://1dois.com.br">1Dois</a>.
        </CopyrightContent>
      </Copyright>
    </Container>
  );
}

export default Footer;
