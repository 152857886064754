import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "../../../assets/img/icons/iphone.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  margin-top: 2rem;
  padding: 25px;
  border-radius: 4px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  font-weight: bold;
`;

export const Card = styled.div`
  justify-content: center;

  background: var(--primary-main);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  width: 45rem;
  border-radius: 20px;
  padding: 2rem;
`;

export const HeaderImage = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

export const IphoneLogo = styled.img.attrs({
  src: Logo,
  alt: "Compro iPhone",
})`
  width: 170px;
  border: 10px solid #fff;
  border-radius: 50%;
`;

export const Slogan = styled.p`
  flex: 1;
  display: flex;
  color: var(--font-tittle);
  flex-direction: column;
  margin-top: 20px;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 0.5rem;

  select {
    height: 35px;
    border: solid 1px var(--primary-darker);
    background: var(--primary-main);
    border-radius: 5px;
  }

  option {
    width: 100%;
    border-radius: 50%;
  }
`;

export const LabelField = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SellButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 45px;
  background: var(--primary-button);
  margin-top: 2rem;

  font-weight: bold;
  font-size: 16px;
  color: var(--primary);
  text-decoration: none;

  cursor: pointer;
  border: none;
  border-radius: 5px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    opacity: 0.5;
  }
`;
