import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Container,
  Divisor,
  Header,
  Title,
  Discount,
  PaymentCard,
  PaymentCardHeader,
  PaymentContent,
  PhoneName,
  BuyPrice,
  AcessoriesContainer,
  AcessoriesTitle,
  AcessoriesItems,
  DefectsContainer,
  DefectsTitle,
  DefectsItems,
  SubtotalContainer,
  WarningContainer,
  WarningText,
  FinalPrice,
  ActionContainer,
  SellButton,
} from "./styles";

function Checkout() {
  const [phone, setPhone] = useState({});
  const [accessories, setAccessories] = useState([]);
  const [defects, setDefects] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [selectedPhone, setSelectedPhone] = useState({});
  const [paidPrice, setPaidPrice] = useState(0);

  const location = useLocation();

  const easyCalc = (accessories) => {
    let calculate = 0;

    accessories.map((item) => {
      calculate = calculate + item.price;
    });

    return calculate;
  };

  const finalCalc = (totalDebits, initial, minimum) => {
    const finalPrice = initial + totalDebits;

    if (finalPrice < minimum) {
      return minimum;
    } else {
      return finalPrice;
    }
  };

  useEffect(() => {
    const {
      accessories: accessoriesLocation,
      defects: defectsLocation,
      phone: phoneLocation,
      selectedPhone: selectedPhoneLocation,
    } = location.state;

    console.log(location.state);

    setSelectedPhone(selectedPhoneLocation);

    setPhone(phoneLocation);
    setAccessories(accessoriesLocation);
    setDefects(defectsLocation);

    const totalAccessories =
      accessoriesLocation.length > 0 ? easyCalc(accessoriesLocation) : 0;
    const totalDefects =
      defectsLocation.length > 0 ? easyCalc(defectsLocation) : 0;

    console.log("totalAccessories", totalAccessories);
    console.log("totalDefects", totalDefects);

    const totalDebits = totalDefects + totalAccessories;
    console.log(" totalDebits", totalDebits);

    const finalPrice = finalCalc(
      totalDebits,
      phoneLocation.initialPrice,
      phoneLocation.minPrice
    );

    setSubtotal(finalPrice);

    setPaidPrice(
      finalPrice < phoneLocation.minPrice ? phoneLocation.minPrice : finalPrice
    );
  }, [location.state]);

  return (
    <Container>
      <Header>
        <Title>Resumo da Proposta</Title>
        <Discount>
          Disponibilizamos retirada por motoboy grátis em SP Capital (sujeito à
          restrição de região)
        </Discount>
      </Header>
      <PaymentCard>
        <PaymentCardHeader>Pagamento Imediato</PaymentCardHeader>
        <PaymentContent>
          <PhoneName>{`${phone?.title} ${selectedPhone.color} - ${selectedPhone.memory}GB`}</PhoneName>
          <BuyPrice>
            <span>Preço de Compra</span>
            <strong>R$ {phone.initialPrice},00</strong>
          </BuyPrice>

          {accessories.length > 0 && (
            <>
              <Divisor />
              <AcessoriesContainer>
                <AcessoriesTitle>Acessórios</AcessoriesTitle>
                {accessories.map((item) => (
                  <AcessoriesItems key={item.name}>
                    <span>{item.name}</span>
                    <strong>+{item.price}</strong>
                  </AcessoriesItems>
                ))}
              </AcessoriesContainer>
            </>
          )}

          {defects.length > 0 && (
            <>
              <Divisor />

              <DefectsContainer>
                <DefectsTitle>Quebrados</DefectsTitle>
                {defects.map((item) => (
                  <DefectsItems key={item.name}>
                    <span>{item.name}</span>
                    <strong>{item.price}</strong>
                  </DefectsItems>
                ))}
              </DefectsContainer>
            </>
          )}

          <Divisor />

          <SubtotalContainer>
            <strong>Subtotal</strong>
            <strong>R$ {paidPrice},00</strong>
          </SubtotalContainer>

          {subtotal < phone.minPrice && (
            <WarningContainer>
              <WarningText>
                Ops! O valor de descontos é maior que nosso preço de compra.
                Nesses casos oferecemos um preço de compra mínimo.
              </WarningText>
            </WarningContainer>
          )}
        </PaymentContent>

        <Divisor />

        <FinalPrice>
          <strong>Valor que pagamos</strong>
          <strong>R$ {paidPrice},00</strong>
        </FinalPrice>

        <ActionContainer>
          <SellButton>Quero Vender</SellButton>
        </ActionContainer>
      </PaymentCard>
    </Container>
  );
}

export default Checkout;
