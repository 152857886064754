import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ArrowRight, ArrowLeft } from "@styled-icons/feather";

import IphonesModelos from "../../services/data";

import {
  Container,
  AcessoriesButton,
  CheckboxStyleContainer,
  AcessoriesCheckbox,
  ButtonsContainer,
  ContinueButton,
  ArrowsButtonContainer,
  FinishButton,
} from "./styles";

function PhoneAttributes() {
  const location = useLocation();
  const phone = location.state;

  const [Iphone, setIphone] = useState(null);

  const [allIphoneAcessories, setAllIphoneAcessories] = useState();
  const [allIphoneDefects, setAllIphoneDefects] = useState();

  const [selectedAcessories, setSelectedAcessories] = useState();

  const [accessoriesView, setAccessoriesView] = useState(true);
  const [defectsView, setDefectsView] = useState(false);

  useEffect(() => {
    const foundIphone = IphonesModelos.find((item) => item.id === phone.model);

    setIphone(foundIphone);
    setAllIphoneAcessories(foundIphone.acessories);
    setAllIphoneDefects(foundIphone.defects);
  }, [phone]);

  let selectedAcessoriesArray = [];
  var selectedDefectArray = [];

  const handleSetAccessory = (accessory) => {
    selectedAcessoriesArray.push(accessory);
  };

  const handleSetDefect = (defect) => {
    selectedDefectArray.push(defect);
  };

  const goToDefectsScreens = () => {
    setSelectedAcessories(selectedAcessoriesArray);
    setAccessoriesView(false);
    setDefectsView(true);
  };

  const goToAcessoriesScreen = () => {
    setAccessoriesView(true);
    setDefectsView(false);
  };

  return (
    <Container>
      {accessoriesView && (
        <>
          <ButtonsContainer>
            {allIphoneAcessories &&
              allIphoneAcessories.map((item) => (
                <CheckboxStyleContainer>
                  <AcessoriesCheckbox
                    onChange={() => {
                      handleSetAccessory(item);
                    }}
                    key={item.name}
                    selected={item.selected}
                  />
                  <strong>{item.name}</strong>
                </CheckboxStyleContainer>
              ))}
          </ButtonsContainer>
          <ContinueButton onClick={goToDefectsScreens}>
            Próximo{" "}
            <ArrowRight style={{ marginLeft: 10 }} size={18} color="#FFF" />
          </ContinueButton>
        </>
      )}

      {defectsView && (
        <>
          <ButtonsContainer>
            {allIphoneDefects &&
              allIphoneDefects.map((item) => (
                <CheckboxStyleContainer>
                  <AcessoriesCheckbox
                    onChange={() => {
                      handleSetDefect(item);
                    }}
                    key={item.name}
                    selected={item.selected}
                  />
                  <span></span>
                  <strong>{item.name}</strong>
                </CheckboxStyleContainer>
              ))}
          </ButtonsContainer>
          <ArrowsButtonContainer>
            <ContinueButton return onClick={goToAcessoriesScreen}>
              <ArrowLeft style={{ marginRight: 10 }} size={18} color="#FFF" />{" "}
              Voltar
            </ContinueButton>

            <FinishButton
              to={{
                pathname: "/checkout",
                state: {
                  phone: Iphone,
                  accessories: selectedAcessories,
                  defects: selectedDefectArray,
                  selectedPhone: phone,
                },
              }}
            >
              Finalizar{" "}
              <ArrowRight style={{ marginLeft: 10 }} size={18} color="#FFF" />
            </FinishButton>
          </ArrowsButtonContainer>
        </>
      )}
    </Container>
  );
}

export default PhoneAttributes;
