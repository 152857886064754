import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 25px;
  border-radius: 4px;

  margin-top: 2rem;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  font-weight: bold;

  background: var(--primary-main);
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;

  font-size: 16px;
`;

export const LocationContent = styled.div`
  font-size: 13px;
`;

export const Copyright = styled.div`
  font-size: 14px;
  margin: 5% 0 0 0;
`;

export const CopyrightContent = styled.div``;
