import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import IphonesModelos from "../../../services/data.js";

import {
  Container,
  Card,
  HeaderImage,
  IphoneLogo,
  Slogan,
  Field,
  LabelField,
  ButtonContainer,
  SellButton,
} from "./styles";

function Main() {
  const [selectedIphone, setSelectedIphone] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [selectedMemory, setSelectedMemory] = useState();

  function handleSetModel(event) {
    setSelectedIphone(Number(event.target.value));
  }

  function handleSetColor(event) {
    setSelectedColor(event.target.value);
  }

  function handleSetMemory(event) {
    setSelectedMemory(event.target.value);
  }

  return (
    <Container>
      <Card>
        <HeaderImage className="Header-Image">
          <IphoneLogo />
        </HeaderImage>
        <Slogan>Venda agora o seu iPhone!</Slogan>
        <Field>
          <LabelField htmlFor="title">Modelo</LabelField>
          <select
            onChange={handleSetModel}
            defaultValue="0"
            value={selectedIphone}
            name="Modelo"
            id="Modelo"
          >
            <option value="0" disabled>
              Selecione o modelo
            </option>
            {IphonesModelos.map((item) => (
              <option key={uuid()} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </Field>

        {selectedIphone && (
          <Field>
            <LabelField htmlFor="memory">Memória Interna</LabelField>
            <select
              value={selectedMemory}
              defaultValue="0"
              onChange={handleSetMemory}
              name="Memory"
              id="Memory"
            >
              <option value="0" disabled>
                Selecione uma opção
              </option>
              {IphonesModelos[selectedIphone].memory.map((item) => {
                return (
                  <option key={item} value={item}>
                    {`${item}GB`}
                  </option>
                );
              })}
            </select>
          </Field>
        )}

        {selectedMemory && (
          <Field>
            <LabelField htmlFor="color">Cor</LabelField>
            <select
              value={selectedColor}
              defaultValue="0"
              onChange={handleSetColor}
              name="Color"
              id="Color"
            >
              <option value="0" disabled>
                Selecione uma opção
              </option>
              {IphonesModelos[selectedIphone].color.map((item) => {
                return (
                  <option key={uuid()} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </Field>
        )}

        {selectedIphone && selectedMemory && selectedColor && (
          <ButtonContainer>
            <SellButton
              to={{
                pathname: "/attributes",
                state: {
                  model: selectedIphone,
                  memory: selectedMemory,
                  color: selectedColor,
                },
              }}
            >
              Ver preço
            </SellButton>
          </ButtonContainer>
        )}
      </Card>
    </Container>
  );
}

export default Main;
